import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <div id="Footer">
            <p>Copyright Jon Long 2019.</p>
            <p>Powered by <b>Dunkin' Donuts Iced Coffee</b></p>
        </div>
    );
};

export default Footer;